<template>
  <div>
    <div class="container-fluid">
      <PageTitle noAdd />

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-lg-12 border-left">
                <div class="card-body">
                  <div class="row align-items-center justify-content-end">
                    <div class="col-md-3">
                      <b-input-group size="lg" class="w-100">
                        <template #prepend>
                          <b-input-group-text>
                            <i class="fas fa-filter"></i>
                          </b-input-group-text>
                        </template>
                        <VSelect
                          v-model="filter.month"
                          :options="bulan"
                          placeholder="-- Filter Bulan --"
                          class="input-group-select"
                          :reduce="v=>String(v.value)" 
                          @input="doFilter"
                        />
                      </b-input-group>
                    </div>
                    <div class="col-md-3" v-if="user.role.indexOf('isAdministratorSystem')>-1 || user.role.indexOf('isAuditor')>-1">
                      <b-input-group size="lg" class="w-100">
                        <template #prepend>
                          <b-input-group-text>
                            <i class="fas fa-filter"></i>
                          </b-input-group-text>
                        </template>
                        <VSelect
                          v-model="filter.level"
                          :options="mrBranchLevel"
                          placeholder="-- Filter Level --"
                          class="input-group-select"
                          :reduce="v=>v.value" 
                          @input="doFilter"
                        />
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div 
                      v-if="!dataList"
                      class="col-md-12"
                    >
                      <div class="justify-content-center py-4 d-flex text-center text-danger align-items-center">
                        <b-spinner class="align-middle mr-3"></b-spinner>
                        <strong>Loading</strong>
                      </div>
                    </div>
                    <div 
                      v-else-if="(dataList||[]).length"
                      class="col-md-12"
                    >
                      <mapbox-map
                        :access-token="MAPBOX_KEY"
                        map-style="mapbox://styles/mapbox/streets-v11"
                        :center="mapCenter"
                        :drag-rotate="false"
                        :zoom="4"
                        style="height: 500px"
                        ref="mapbox"
                        >
                        <span
                          v-for="(v,k) in dataList" 
                          :key="k" 
                        >
                          <mapbox-marker 
                            v-if="v.mo_lang||v.mo_lat"
                            :lng-lat="[+v.mo_lang, +v.mo_lat]" 
                            class="finger"
                          >
                            <svg @click="doView(v.mo_id)"
                              class="finger custom-marker" display="block" height="41px" width="27px" viewBox="0 0 27 41"
                            >
                              <g fill-rule="nonzero"><g transform="translate(3.0, 29.0)" fill="#000000"><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="10.5" ry="5.25002273"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="9.5" ry="4.77275007"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="8.5" ry="4.29549936"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="7.5" ry="3.81822308"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="6.5" ry="3.34094679"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="5.5" ry="2.86367051"></ellipse><ellipse opacity="0.04" cx="10.5" cy="5.80029008" rx="4.5" ry="2.38636864"></ellipse></g><g fill="#ED2939"><path d="M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z"></path></g><g opacity="0.25" fill="#000000"><path d="M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z"></path></g><g transform="translate(6.0, 7.0)" fill="#FFFFFF"></g><g transform="translate(8.0, 8.0)"><circle fill="#000000" opacity="0.25" cx="5.5" cy="5.5" r="5.4999962"></circle><circle fill="#FFFFFF" cx="5.5" cy="5.5" r="5.4999962"></circle></g></g>
                            </svg>
                          </mapbox-marker>
                        </span>
                      </mapbox-map>
                    </div>
                    <div v-else>
                      <center><h4>Tidak Ada Data.</h4></center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal 
      id="ModalDetail" 
      :title="'10 Risiko Teratas - '+((row.kantor||{}).mo_name||'')+' - '+monthName+' '+year"
      size="xl"
      ok-only
      :hide-footer="true"
      no-close-on-backdrop
      dialog-class="modal-profile"
    >
      <template v-if="row.kantor">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="row.kantor.mo_level !== 'pusat'">
          <div class="table-responsive">
            <table class="table product-overview table-bordered">
              <thead>
                <tr>
                  <th class="full-color-table full-dark-table" style="vertical-align: middle;" rowspan="2">#</th>
                  <th class="full-color-table full-dark-table" style="vertical-align: middle;min-width:200px" rowspan="2">Nama Resiko</th>
                  <th 
                    class="text-center full-color-table full-dark-table " 
                    colspan="3" rowspan="1"
                  >
                    {{ monthName }}
                  </th>
                </tr>
                <tr>
                  <!-- <template v-for="v in 12"> -->
                    <th class=" full-color-table full-danger-table text-center" colspan="1" rowspan="1">
                      INHEREN KD
                    </th>
                    <th class="text-center full-color-table full-warning-table" colspan="1" rowspan="1">
                      CURRENT KD
                    </th>
                    <th class="text-center full-color-table full-info-table" colspan="1" rowspan="1">
                      RESIDUAL KD
                    </th>
                  <!-- </template> -->
                </tr>
              </thead>
              <tbody>
                <template v-if="(row.risiko||[]).length">
                  <tr 
                    v-for="(v,k) in (row.risiko||[])"
                    :key="k"
                    role="row" 
                    class="odd"
                  >
                    <td class="text-center">{{k+1}}</td>
                    <td>{{v.name}}</td>
                    <!-- <template v-for="v2 in 12"> -->
                      <td
                        :key="'.a.'+month" 
                        class=" text-center"
                      >
                        {{ (v.data[filter.month||month]||{}).inheren_kd ? removeDecimal((v.data[filter.month||month]||{}).inheren_kd) :'-' }}
                      </td>
                      <td
                        :key="'.b.'+month" 
                        class=" text-center"
                      >
                        {{ (v.data[filter.month||month]||{}).current_kd ? removeDecimal((v.data[filter.month||month]||{}).current_kd) :'-' }}
                      </td>
                      <td
                        :key="'.c.'+month" 
                        class=" text-center"
                      >
                        {{ (v.data[filter.month||month]||{}).risidual_kd ? removeDecimal((v.data[filter.month||month]||{}).risidual_kd) :'-' }}
                      </td>
                    <!-- </template> -->
                  </tr>
                </template>
                <tr v-else>
                  <th colspan="30">Tidak ada risiko terdaftar di Bulan ini.</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <VTab v-if="row.kantor.mo_level === 'pusat'">
          <template v-if="
            (
              user.role.indexOf('isAdministratorSystem')>-1 ||
              user.role.indexOf('isAuditor')>-1
            ) ||
            (
              (
                user.role.indexOf('isAdministratorSystem')<=-1 && 
                user.role.indexOf('isAuditor')<=-1
              ) &&
              user.officeLevel != 'pusat'
            )
          " slot="link">
            <VTabLink :step.sync="tab_divisi" option="all">Semua</VTabLink>
            <VTabLink :step.sync="tab_divisi" :option="v.muk_id" v-for="(v,k) in row.unit_kerja" :key="k">{{v.muk_description}}</VTabLink>
          </template>

          <template slot="content">
            <b-overlay :show="loadingDivision">
              <VTabContent :step.sync="tab_divisi" :option="tab_divisi">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-3">
                  <div class="table-responsive">
                    <table class="table product-overview table-bordered">
                      <thead>
                        <tr>
                          <th class="full-color-table full-dark-table" style="vertical-align: middle;" rowspan="2">#</th>
                          <th class="full-color-table full-dark-table" style="vertical-align: middle;min-width:200px" rowspan="2">Nama Resiko</th>
                          <th 
                            class="text-center full-color-table full-dark-table " 
                            colspan="3" rowspan="1"
                          >
                            {{ monthName }}
                          </th>
                        </tr>
                        <tr>
                          <!-- <template v-for="v in 12"> -->
                            <th class=" full-color-table full-danger-table text-center" colspan="1" rowspan="1">
                              INHEREN KD
                            </th>
                            <th class="text-center full-color-table full-warning-table" colspan="1" rowspan="1">
                              CURRENT KD
                            </th>
                            <th class="text-center full-color-table full-info-table" colspan="1" rowspan="1">
                              RESIDUAL KD
                            </th>
                          <!-- </template> -->
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="(row.risiko||[]).length">
                          <tr 
                            v-for="(v,k) in (row.risiko||[])"
                            :key="k"
                            role="row" 
                            class="odd"
                          >
                            <td class="text-center">{{k+1}}</td>
                            <td>{{v.name}}</td>
                            <!-- <template v-for="v2 in 12"> -->
                              <td
                                :key="'.a.'+month" 
                                class=" text-center"
                              >
                                {{ (v.data[filter.month||month]||{}).inheren_kd ? removeDecimal((v.data[filter.month||month]||{}).inheren_kd) :'-' }}
                              </td>
                              <td
                                :key="'.b.'+month" 
                                class=" text-center"
                              >
                                {{ (v.data[filter.month||month]||{}).current_kd ? removeDecimal((v.data[filter.month||month]||{}).current_kd) :'-' }}
                              </td>
                              <td
                                :key="'.c.'+month" 
                                class=" text-center"
                              >
                                {{ (v.data[filter.month||month]||{}).risidual_kd ? removeDecimal((v.data[filter.month||month]||{}).risidual_kd) :'-' }}
                              </td>
                            <!-- </template> -->
                          </tr>
                        </template>
                        <tr v-else>
                          <th colspan="30">Tidak ada risiko terdaftar di Bulan ini.</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </VTabContent>
            </b-overlay>
          </template>
        </VTab>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import MapboxMap from '@studiometa/vue-mapbox-gl/dist/components/MapboxMap';
import MapboxMarker from '@studiometa/vue-mapbox-gl/dist/components/MapboxMarker';
import 'mapbox-gl/dist/mapbox-gl.css';

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'


export default {
  extends: GlobalVue,
  components: {PageTitle, MapboxMap, MapboxMarker,
    VTab, VTabLink, VTabContent},
  data(){
    return {
      a:process.env,
      MAPBOX_KEY:process.env.VUE_APP_MAPBOX_KEY,
      mrBranchLevel: [],
      isLoading: false,
      row: [],
      tab_divisi: 1,
      loadingDivision: false,
      mapCenter : [117.06226474009497,-2.705940827375443],
      bulan: [
        {value: 1, label: "Januari"},
        {value: 2, label: "Februari"},
        {value: 3, label: "Maret"},
        {value: 4, label: "April"},
        {value: 5, label: "Mei"},
        {value: 6, label: "Juni"},
        {value: 7, label: "Juli"},
        {value: 8, label: "Agustus"},
        {value: 9, label: "September"},
        {value: 10, label: "Oktober"},
        {value: 11, label: "November"},
        {value: 12, label: "Desember"},
      ]
    }
  },
  methods: {
    doView(kantor_id){
      this.loadingOverlay=true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data:Object.assign((this.filter||{}), {
            type:'view-detail',
            id:kantor_id
          })
        },
        'POST'
      ).then((resp)=>{
        this.loadingOverlay=false
        this.tab_divisi='all'
        this.$bvModal.show('ModalDetail')
        setTimeout(() => {
          this.row = resp.data.row
        }, 0)
        
      })
    },
    removeDecimal(text){
      return +text.toString()
    }
  },
  mounted(){
    this.apiGet({query: {level: this.filter.level||this.user.officeLevel}})
  },
  watch:{
    $route(){
      this.apiGet({query: {level: this.filter.level||this.user.officeLevel}})
    },
    'tab_divisi'(value){
      if(!value || value=='all') return;
      
      this.loadingDivision = true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data:Object.assign((this.filter||{}),{
            type:'view-detail',
            id:this.row.kantor.mo_id,
            unit_kerja: [value]
          })
        },
        'POST'
      ).then((resp)=>{
        setTimeout(() => {
          this.loadingDivision = false
          this.row = resp.data.row
        }, 0)
        
      })
    }
  },
  computed: {
    date(){
      return `${new Date().getFullYear()}-${this.filter.month||new Date().getMonth()+1}-${new Date().getDate()}`
    },
    month(){
      return new Date().getMonth()+1
    },
    year(){
      return new Date().getFullYear()
    },
    day(){
      return new Date().getDate()
    },
    monthName(){
      return this.$moment(`${this.year}-${this.filter.month||this.month}-${this.day}`).format("MMMM")
    },
    
  }

}
</script>

<style lang="scss" scoped>
.input-group-prepend{
  width: 15%;
}
.input-group-text {
  width: 100%;
  justify-content: center;
}
.input-group-select{
  width: 85%;
}
</style>